import Vue from 'vue';
import { createPinia, PiniaVuePlugin, defineStore } from 'pinia';
//import Vuex from 'vuex';

Vue.use(PiniaVuePlugin);
const pinia = createPinia();

export const useStore = defineStore('main', {
  state: () => {
    return {
      alert: false,
      alertMessage: '',
      uploadReady: false,
      isUploading: false,
      charts: {
        elevation: null,
        points: null,
      },
      fileName: '',
      showMap: false,
      legendIsHidden: false,
    };
  },
  getters: {
    elevationChartData: (state) => {
      return state.charts.elevation;
    },
    pointsChartData: (state) => {
      return state.charts.points;
    },
  },
  // could also be defined as
  // state: () => ({ count: 0 })
  actions: {
    increment() {
      //this.count++;
    },
    updateElevationChartData(data: any) {
      this.charts.elevation = data;
    },
    updatePointsChartData(data: any) {
      this.charts.points = data;
      this.showMap = true;
    },
    toggleUpload() {
      this.isUploading = this.isUploading === false ? true : false;
    },
    setUpload(data: any) {
      this.uploadReady = data;
    },
    setFileName(data: any) {
      this.fileName = data;
    },
    setAlert(data: any) {
      this.isUploading = false;
      this.alert = data.alert;
      this.alertMessage = data.alertMessage;
      this.fileName = '';
    },
    toggleLegend() {
      this.legendIsHidden = this.legendIsHidden === false ? true : false;
    },
  },
});

/*
export default new Vuex.Store({
  state: {
    alert: false,
    alertMessage: '',
    uploadReady: false,
    isUploading: false,
    charts: {
      elevation: null,
      points: null,
    },
    fileName: '',
    showMap: false,
    legendIsHidden: false,
  },
  getters: {
    elevationChartData: (state) => {
      return state.charts.elevation;
    },
    pointsChartData: (state) => {
      return state.charts.points;
    },
  },
  mutations: {
    updateElevationChartData(state, data) {
      state.charts.elevation = data;
    },
    updatePointsChartData(state, data) {
      state.charts.points = data;
      state.showMap = true;
    },
    toggleUpload(state) {
      state.isUploading = state.isUploading === false ? true : false;
    },
    setUpload(state, data) {
      state.uploadReady = data;
    },
    setFileName(state, data) {
      state.fileName = data;
    },
    setAlert(state, data) {
      state.isUploading = false;
      state.alert = data.alert;
      state.alertMessage = data.alertMessage;
      state.fileName = '';
    },
    toggleLegend(state) {
      state.legendIsHidden = state.legendIsHidden === false ? true : false;
    },
  },
  actions: {},
});
*/
