
export default class ConfigProvider {
  public static get CONFIG(): { [key: string]: string }  {
    return {
      backendHost: '$VUE_APP_BACKEND_BASE_URL',
      buildNumber: '$VUE_APP_BUILD_NUMBER',
      buildVersion: '$VUE_APP_VERSION',
      buildDate: '$VUE_APP_BUILD_DATE'
    };
  }

  public static value(name: string): string {
    if (!(name in this.CONFIG)) {
      return '';
    }

    const value = this.CONFIG[name];

    if (!value) {
      return '';
    }

    if (value.startsWith('$VUE_APP_')) {
      const envName = value.substr(1);
      const envValue = process.env[envName];
      if (envValue) {
        return envValue;
      } else {
        return '';
      }
    } else {
      return value;
    }
  }
}
