import axios from 'axios';
import Configuration from './ConfigProvider';

const proxyURL = Configuration.value('backendHost');

const instance = axios.create({
  baseURL: proxyURL || 'http://localhost:3000',
});

export default instance;
