
import { latLng, LatLng, Map, Polyline } from 'leaflet';
import { LMap, LTileLayer, LPolyline } from 'vue2-leaflet';
import { useStore } from '@/store/index';
import { mapStores, mapState } from 'pinia';

declare interface BasePolyLine {
  latlngs: LatLng[];
  color: string;
}
declare interface BaseComponentData {
  map: Map | null;
  zoom: number;
  center: LatLng;
  url: string;
  attribution: string;
  currentZoom: number;
  currentCenter: LatLng;
  polyline: Polyline | null;
  polylineFoo: BasePolyLine;
  bounds: any;
}

import Vue from 'vue'
export default Vue.extend({
  name: 'MapView',
  components: {
    LMap,
    LTileLayer,
    LPolyline,
  },
  data(): BaseComponentData {
    return {
      map: null,
      zoom: 13,
      center: latLng(44.173889, 5.277222),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 11.5,
      currentCenter: latLng(44.173889, 5.277222),
      polyline: null,
      polylineFoo: {
        latlngs: [],
        color: 'green',
      },
      bounds: null,
    };
  },
  methods: {
    zoomUpdate(zoom: number): void {
      this.currentZoom = zoom;
    },
    centerUpdate(center: LatLng): void {
      this.currentCenter = center;
      if (this.map != null && this.polyline != null) {
        this.map.fitBounds(this.polyline.getBounds());
      }
    },
    doSomethingOnReady(): void {
      if (this.$refs.map != undefined && this.$refs.polyline != undefined) {
        this.map = (this.$refs.map as unknown as LMap).mapObject;
        this.polyline = (this.$refs.polyline as unknown as LPolyline).mapObject;
        this.center = latLng(
          this.polylineFoo.latlngs[0].lat,
          this.polylineFoo.latlngs[0].lng,
        );
        this.centerUpdate(this.center);
      }
    },
  },
  computed: {
    ...mapStores(useStore),
    ...mapState(useStore, ['charts']),
    showMap(): any {
      return this.mainStore.showMap;
    },
    polylineData(): any {
      return this.mainStore.charts.points;
    },
  },
  watch: {
    polylineData(newData, oldData) {
      this.polylineFoo.latlngs = newData;
      this.center = latLng(newData[0].lat, newData[0].lon, newData[0].ele);
    },
  },
});
