<template>
    <v-row align="center">
      <v-col cols="12">
        <v-file-input
          v-model="selectedFiles"
          name="gpxfile"
          type="file"
          ref="file"
          id="file"
          accept=".gpx"
          label="Click here to choose and upload your gpx file."
          @change="upload()"
        ></v-file-input>
      </v-col>
      <!--
      <v-col>
        <v-btn color="primary" v-on:click="upload()">Upload gpx file</v-btn>
      </v-col>
      -->
    </v-row>
</template>
<script>
import UploadService from '../services/UploadService';
import { useStore } from '@/store/index';
import { mapStores } from 'pinia';

export default {
  name: 'upload-files',
  computed: {
    ...mapStores(useStore),
  },
  data() {
    return {
      selectedFiles: undefined,
      currentFile: undefined,
      progress: 0,
      message: '',
      fileInfos: [],
    };
  },
  methods: {
    selectFile() {
      this.selectedFiles = this.$refs.file.files;
    },
    upload() {
      this.progress = 0;
      this.currentFile = this.selectedFiles;
      this.mainStore.setAlert({alert: false});
      this.mainStore.setFileName(this.currentFile.name);
      UploadService.upload(this.currentFile, (event) => {
        this.progress = Math.round((100 * event.loaded) / event.total);
      })
        .then((response) => {
          this.message = response.data.message;
          if(this.message == 'ok') {
            this.mainStore.setUpload(true);
            this.mainStore.updateElevationChartData(JSON.parse(response.data.section));
            this.mainStore.updatePointsChartData(JSON.parse(response.data.track));
            return UploadService.getFiles();
          } else {
            this.mainStore.setUpload(false);
            this.mainStore.setAlert({alert: true, alertMessage: 'Parsing failed.'});
            throw new Error("Parsing failed.")
          }
        })
        .then((files) => {
          this.fileInfos = files.data;
        })
        .catch((error) => {
          this.progress = 0;
          this.message = error;
          this.currentFile = undefined;
        });

      // this.selectedFiles = undefined;
    },
  },
  mounted() {
    /*
    UploadService.getFiles().then((response) => {
      this.fileInfos = response.data;
    });
    */
  },
};
</script>
