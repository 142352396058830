import axios from 'axios';
import Configuration from './ConfigProvider';
//import store from '../store';

const proxyURL = Configuration.value('backendHost');

const instance = axios.create({
  baseURL: proxyURL || 'http://localhost:3000',
/*
  headers: {
    'Content-type': 'multipart/form-data',
  },
  */
});

// before a request is made start the nprogress
instance.interceptors.request.use((config) => {
//  store.commit('toggleUpload');
  return config;
});

// before a response is returned stop nprogress
instance.interceptors.response.use((response) => {
//  store.commit('toggleUpload');
  return response;
});

export default instance;
