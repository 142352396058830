<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <silent-box :gallery="gallery"></silent-box>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import VueSilentbox from 'vue-silentbox';
import httpProfile from '../plugins/http-profile';
import Configuration from '../plugins/ConfigProvider';

Vue.use(VueSilentbox);

const proxyURL = Configuration.value('backendHost');

export default {
  name: 'GalleryView',
  data() {
    return {
      gallery: [],
    };
  },
  mounted() {
    httpProfile.get('/api/profiles', {
      headers: {
        'Content-Type': 'application/json',
      },

    }
    ).then((response) => {
      response.data.forEach((file) => {
        this.gallery.push({
          src: (proxyURL || 'http://localhost:3000/') + 'api/profile/' + file.name,
          thumbnail: (proxyURL || 'http://localhost:3000/') + 'api/profile/' + file.name + '?thumb=true',
          thumbnailWidth: '220px',
          thumbnailHeight: '100px',
        });
      });
    });
  },
};
</script>

<style>
.silentbox-item {
  max-height: 145px;
  border-radius: 0.5rem;
  margin-right: 1rem;
  overflow: hidden;
}

.silentbox-item:last-of-type {
  margin-right: 0;
}
</style>

<style scoped lang="scss">



</style>
