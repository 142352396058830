
import Vue from 'vue';
import ElevationChart from '../components/ElevationChart.vue';
import Upload from '../components/Upload.vue';
import MapComponent from '../components/Map.vue';
import LoadingIcon from '../assets/loading.svg?inline';
import Gallery from '../components/Gallery.vue';
import { useStore } from '@/store/index';
import { mapStores } from 'pinia';

export default Vue.extend({
  name: 'HomeView',
  computed: {
    ...mapStores(useStore),
    alert() {
      return this.mainStore.alert
    },
     isLoading () {
      return this.mainStore.isUploading
    },
    showDownload() {
      return this.mainStore.uploadReady
    },
    showTools () {
      return false
    },
  },
  components: {
    Upload,
    MapComponent,
    ElevationChart,
    LoadingIcon,
    Gallery,
  },
  methods: {
    download: function() {
      (this.$refs.elevationChart as Vue & {
        exportPNG: () => void;
      }).exportPNG();
    },
    toggleLegend: function() {
      //this.$store.commit('toggleLegend');
      (this.$refs.elevationChart as Vue & {
        toggleLegend: () => void;
      }).toggleLegend();
    }
  },
});
