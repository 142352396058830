import Vue from 'vue';
// import Vuetify from 'vuetify/lib';
import Vuetify from 'vuetify';
import { Iconfont } from 'vuetify/types/services/icons';
import 'typeface-roboto/index.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';

Vue.use(Vuetify);

const opts = {
    icons: {
      iconfont: 'md' as Iconfont,
    },
  };

export default new Vuetify(opts);
