
import UploadService from '../services/UploadService';
import { useStore } from '@/store/index';
import { mapStores, mapState } from 'pinia';
import { XYChart } from '@/plugins/amcharts';

//const amcharts = await import('@/plugins/amcharts');
//import { useTheme, create, ColorSet, color, NumberFormatter, Label } from '@amcharts/amcharts4/core';
//import { XYChart, CategoryAxis, ValueAxis, LineSeries, Legend, XYCursor } from '@amcharts/amcharts4/charts';
//import am4themesAnimated from '@amcharts/amcharts4/themes/animated';

declare interface IElevationChart {
  chart: XYChart | null;
}

import Vue from 'vue'
export default Vue.extend({
  name: 'ElevationChart',
  data(): IElevationChart {
    return {
      chart: null,
    };
  },

  methods: {
    drawChart(elevationChartData : any): void {

      import('@/plugins/amcharts').then((amcharts) => {

        //amcharts.useTheme(amcharts.theme);
        const chart = amcharts.create(
          this.$refs.chartdiv as HTMLElement,
          amcharts.XYChart,
        );

        const colorSet = new amcharts.ColorSet();
        colorSet.list = [
          amcharts.color('rgb(84,217,84)'),
          amcharts.color('rgb(99,216,76)'),
          amcharts.color('rgb(115,215,68)'),
          amcharts.color('rgb(130,215,61)'),
          amcharts.color('rgb(146,214,53)'),
          amcharts.color('rgb(161,214,45)'),
          amcharts.color('rgb(177,213,38)'),
          amcharts.color('rgb(192,213,30)'),
          amcharts.color('rgb(208,212,22)'),
          amcharts.color('rgb(223,212,15)'),
          amcharts.color('rgb(239,211,7)'),
          amcharts.color('rgb(255,211,0)'),
          amcharts.color('rgb(250,200,0)'),
          amcharts.color('rgb(245,190,0)'),
          amcharts.color('rgb(240,180,0)'),
          amcharts.color('rgb(235,170,0)'),
          amcharts.color('rgb(231,160,0)'),
          amcharts.color('rgb(226,150,0)'),
          amcharts.color('rgb(221,140,0)'),
          amcharts.color('rgb(216,130,0)'),
          amcharts.color('rgb(212,120,0)'),
          amcharts.color('rgb(207,110,0)'),
          amcharts.color('rgb(202,100,0)'),
          amcharts.color('rgb(197,90,0)'),
          amcharts.color('rgb(193,80,0)'),
          amcharts.color('rgb(188,70,0)'),
          amcharts.color('rgb(183,60,0)'),
          amcharts.color('rgb(178,50,0)'),
          amcharts.color('rgb(174,40,0)'),
          amcharts.color('rgb(169,30,0)'),
          amcharts.color('rgb(164,20,0)'),
          amcharts.color('rgb(159,10,0)'),
          amcharts.color('rgb(155,0,0)'),
        ];

        //chart.paddingRight = 20;

        const data = [];
        let distance = 0;

        for (let i = 0; i < elevationChartData.length - 1; i++) {
          let gradientColor = amcharts.color('green');
          const sectionData = elevationChartData[i];
          const gradient = sectionData.elevation.abs;
          if (gradient < 0) {
            gradientColor = amcharts.color('lightblue');
          } else if (gradient >= 0 && gradient < 0.5) {
            gradientColor = colorSet.getIndex(0);
          } else if (gradient >= 0.5 && gradient < 1) {
            gradientColor = colorSet.getIndex(1);
          } else if (gradient >= 1 && gradient < 1.5) {
            gradientColor = colorSet.getIndex(2);
          } else if (gradient >= 1.5 && gradient < 2) {
            gradientColor = colorSet.getIndex(3);
          } else if (gradient >= 2 && gradient < 2.5) {
            gradientColor = colorSet.getIndex(4);
          } else if (gradient >= 2.5 && gradient < 3) {
            gradientColor = colorSet.getIndex(5);
          } else if (gradient >= 3 && gradient < 3.5) {
            gradientColor = colorSet.getIndex(6);
          } else if (gradient >= 3.5 && gradient < 4) {
            gradientColor = colorSet.getIndex(7);
          } else if (gradient >= 4 && gradient < 4.5) {
            gradientColor = colorSet.getIndex(8);
          } else if (gradient >= 4.5 && gradient < 5) {
            gradientColor = colorSet.getIndex(9);
          } else if (gradient >= 5 && gradient < 5.5) {
            gradientColor = colorSet.getIndex(10);
          } else if (gradient >= 5.5 && gradient < 6) {
            gradientColor = colorSet.getIndex(11);
          } else if (gradient >= 6 && gradient < 6.5) {
            gradientColor = colorSet.getIndex(12);
          } else if (gradient >= 6.5 && gradient < 7) {
            gradientColor = colorSet.getIndex(13);
          } else if (gradient >= 7 && gradient < 7.5) {
            gradientColor = colorSet.getIndex(14);
          } else if (gradient >= 7.5 && gradient < 8) {
            gradientColor = colorSet.getIndex(15);
          } else if (gradient >= 8 && gradient < 8.5) {
            gradientColor = colorSet.getIndex(16);
          } else if (gradient >= 8.5 && gradient < 9) {
            gradientColor = colorSet.getIndex(17);
          } else if (gradient >= 9 && gradient < 9.5) {
            gradientColor = colorSet.getIndex(18);
          } else if (gradient >= 9.5 && gradient < 10) {
            gradientColor = colorSet.getIndex(19);
          } else if (gradient >= 10 && gradient < 10.5) {
            gradientColor = colorSet.getIndex(20);
          } else if (gradient >= 10.5 && gradient < 11) {
            gradientColor = colorSet.getIndex(21);
          } else if (gradient >= 11 && gradient < 11.5) {
            gradientColor = colorSet.getIndex(22);
          } else if (gradient >= 11.5 && gradient < 12) {
            gradientColor = colorSet.getIndex(23);
          } else if (gradient >= 12 && gradient < 12.5) {
            gradientColor = colorSet.getIndex(24);
          } else if (gradient >= 12.5 && gradient < 13) {
            gradientColor = colorSet.getIndex(25);
          } else if (gradient >= 13 && gradient < 13.5) {
            gradientColor = colorSet.getIndex(26);
          } else if (gradient >= 13.5 && gradient < 14) {
            gradientColor = colorSet.getIndex(27);
          } else if (gradient >= 14 && gradient < 14.5) {
            gradientColor = colorSet.getIndex(28);
          } else if (gradient >= 14.5 && gradient < 15) {
            gradientColor = colorSet.getIndex(29);
          } else if (gradient >= 15) {
            gradientColor = colorSet.getIndex(30);
          }

          data.push({
            elevation: sectionData.elevation.max,
            slope: sectionData.elevation.slope,
            meter: (distance * 0.001).toFixed(2),
            lineColor: gradientColor,
          });

          distance += parseFloat(sectionData.distance.total);
        }
        chart.data = data;

        const categoryAxis = chart.xAxes.push(new amcharts.CategoryAxis());
        categoryAxis.dataFields.category = 'meter';
        categoryAxis.startLocation = 0.5;
        categoryAxis.endLocation = 0.5;
        categoryAxis.renderer.grid.template.disabled = true;
        categoryAxis.title.text = 'km';
        categoryAxis.numberFormatter = new amcharts.NumberFormatter();
        categoryAxis.numberFormatter.numberFormat = '#.##';
        categoryAxis.tooltipText = "{meter.formatNumber('#.##')}";

        const valueAxis = chart.yAxes.push(new amcharts.ValueAxis());
        valueAxis.renderer.grid.template.disabled = true;
        valueAxis.title.text = 'm';
        valueAxis.title.rotation = 0;

        chart.series.template = new amcharts.LineSeries();
        const series = chart.series.create();
        series.simplifiedProcessing = true;
        series.dataFields.categoryX = 'meter';
        series.dataFields.valueY = 'elevation';
        (series.dataFields as any).slope = 'slope';
        series.fillOpacity = 0.6;
        series.stroke = amcharts.color('lightblue');
        series.strokeWidth = 2;
        series.propertyFields.fill = 'lineColor';
        (series as any).tensionX = 0.9;
        (series as any).tensionY = 0.9;

        chart.legend = new amcharts.Legend();

        //legend.parent = chart.chartContainer;
        //chart.legend.background.fill = amcharts.color("#000");
        //chart.legend.background.fillOpacity = 0.05;
        chart.legend.width = 100;
        chart.legend.position = 'right';
        //chart.legend.padding(10, 5, 10, 5);
        chart.legend.data = [
          {
            name: '< 0',
            fill: 'lightblue',
          },
        ];

        for (let i = 2; i < colorSet.list.length; i = i + 2) {
          chart.legend.data.push({
            name: i / 2 + ' < ' + (i + 2) / 2,
            fill: colorSet.getIndex(i - 2).hex,
          });
        }

        chart.legend.itemContainers.template.paddingTop = 1;
        chart.legend.itemContainers.template.paddingBottom = 1;
        chart.legend.itemContainers.template.clickable = false;
        chart.legend.itemContainers.template.focusable = false;

        const legendTitle = chart.legend.createChild(amcharts.Label);
        legendTitle.text = 'Slope in %';

        series.tooltipText = "{slope.formatNumber('#.##')}";
        chart.cursor = new amcharts.XYCursor();

        const options = chart.exporting.getFormatOptions('png');
        options.keepTainted = true;
        chart.exporting.setFormatOptions('png', options);

        this.chart = chart;
      });
    },
    exportPNG(): void {
      if (this.chart != null) {
        this.chart.exporting.filePrefix =
          this.getFileName.replace(/\.[^/.]+$/, '') || 'gpxviz';
        this.chart.exporting.export('png');
        const options = this.chart.exporting.getFormatOptions('png');
        options.minWidth = 1920;
        options.minHeight = 1080;
        options.maxWidth = 1920;
        options.maxHeight = 1080;
        this.chart.exporting.getImage('png', options).then(function (imgData) {
          UploadService.uploadProfile(imgData);
        });
      }
    },
    toggleLegend(): void {
      if (this.chart != null) {
        if (this.chart.legend.isHidden) {
          this.chart.legend.show();
        } else {
          this.chart.legend.hide();
        }
      }
    },
  },
  watch: {
    elevationChartData (newData, oldData) {
      this.drawChart(newData);
    },
    /*
    'this.mainStore.charts.elevation'(value) {
      console.log("foo")
      this.drawChart(value);
    },
    */
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  },
  computed: {
    ...mapStores(useStore),
    ...mapState(useStore, ['charts']),
    elevationChartData(): any {
      return this.mainStore.charts.elevation;
    },
    getFileName(): string {
      return this.mainStore.fileName;
    },
  },
});
