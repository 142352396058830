
import Vue from 'vue';
import Configuration from '../plugins/ConfigProvider';
import config from '../../package.json';
export default Vue.extend({
  name: 'AboutView',
  props: {
    value: Boolean,
  },
  computed: {
    dialog: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    getVersion() {
      return Configuration.value('buildVersion');
    },
    getBuildNumber() {
      return Configuration.value('buildNumber');
    },
    getBuildDate() {
      return Configuration.value('buildDate');
    }
  }
});
