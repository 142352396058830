import httpUpload from '../plugins/http-common';
import httpProfile from '../plugins/http-profile';
import {
  AxiosRequestConfig,
} from 'axios';

class UploadService {
  // uploadProgress: (progress: number) => void
  public upload(file: File, uploadProgress: () => void) {
    const formData = new FormData();
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: uploadProgress,
    };

    formData.append('gpxfile', file, 'gpxfile');

    return httpUpload.post('/api/upload', formData, config);
  }

  public uploadProfile(profileData: string) {
    return httpProfile.post(
      '/api/profile',
      { profile: profileData },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }
}

export default new UploadService();
