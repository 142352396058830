
import Vue from 'vue';
import About from './views/About.vue'

export default Vue.extend({
  name: 'GpxvizApp',
  data () {
    return {
      showAbout: false
    }
  },
  components: {
    About
  }
});
